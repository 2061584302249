
const ADDITIONAL_BANKS = [
    { code: "fintech", name: "OUTROS" }
]

/** 
 * The bank codes, according [Banco Central do Brasil](https://www.bcb.gov.br/pom/spb/estatistica/port/ParticipantesSTRport.csv) 
 **/
export const BANK_CODES = ADDITIONAL_BANKS.concat([
    { code: "001", name: "BCO DO BRASIL S.A." },
    { code: "070", name: "BRB - BCO DE BRASILIA S.A." },
    { code: "n/a", name: "Selic" },
    { code: "n/a", name: "Bacen" },
    { code: "430", name: "CCR SEARA" },
    { code: "272", name: "AGK CC S.A." },
    { code: "136", name: "CONF NAC COOP CENTRAIS UNICRED" },
    { code: "407", name: "ÍNDIGO INVESTIMENTOS DTVM LTDA." },
    { code: "104", name: "CAIXA ECONOMICA FEDERAL" },
    { code: "n/a", name: "STN" },
    { code: "077", name: "BANCO INTER" },
    { code: "423", name: "COLUNA S.A. DTVM" },
    { code: "741", name: "BCO RIBEIRAO PRETO S.A." },
    { code: "330", name: "BANCO BARI S.A." },
    { code: "739", name: "BCO CETELEM S.A." },
    { code: "743", name: "BANCO SEMEAR" },
    { code: "100", name: "PLANNER CV S.A." },
    { code: "096", name: "BCO B3 S.A." },
    { code: "747", name: "BCO RABOBANK INTL BRASIL S.A." },
    { code: "362", name: "CIELO S.A." },
    { code: "322", name: "CCR DE ABELARDO LUZ" },
    { code: "748", name: "BCO COOPERATIVO SICREDI S.A." },
    { code: "350", name: "CREHNOR LARANJEIRAS" },
    { code: "752", name: "BCO BNP PARIBAS BRASIL S A" },
    { code: "379", name: "CECM COOPERFORTE" },
    { code: "399", name: "KIRTON BANK" },
    { code: "108", name: "PORTOCRED S.A. - CFI" },
    { code: "378", name: "BBC LEASING" },
    { code: "413", name: "BCO BV S.A." },
    { code: "756", name: "BANCO SICOOB S.A." },
    { code: "360", name: "TRINUS CAPITAL DTVM" },
    { code: "757", name: "BCO KEB HANA DO BRASIL S.A." },
    { code: "102", name: "XP INVESTIMENTOS CCTVM S/A" },
    { code: "084", name: "UNIPRIME NORTE DO PARANÁ - CC" },
    { code: "180", name: "CM CAPITAL MARKETS CCTVM LTDA" },
    { code: "066", name: "BCO MORGAN STANLEY S.A." },
    { code: "015", name: "UBS BRASIL CCTVM S.A." },
    { code: "143", name: "TREVISO CC S.A." },
    { code: "n/a", name: "CIP Siloc" },
    { code: "062", name: "HIPERCARD BM S.A." },
    { code: "074", name: "BCO. J.SAFRA S.A." },
    { code: "099", name: "UNIPRIME CENTRAL CCC LTDA." },
    { code: "387", name: "BCO TOYOTA DO BRASIL S.A." },
    { code: "326", name: "PARATI - CFI S.A." },
    { code: "025", name: "BCO ALFA S.A." },
    { code: "315", name: "PI DTVM S.A." },
    { code: "075", name: "BCO ABN AMRO S.A." },
    { code: "040", name: "BCO CARGILL S.A." },
    { code: "307", name: "TERRA INVESTIMENTOS DTVM" },
    { code: "425", name: "SOCINAL S.A. CFI" },
    { code: "190", name: "SERVICOOP" },
    { code: "296", name: "VISION S.A. CC" },
    { code: "063", name: "BANCO BRADESCARD" },
    { code: "191", name: "NOVA FUTURA CTVM LTDA." },
    { code: "382", name: "FIDUCIA SCMEPP LTDA" },
    { code: "064", name: "GOLDMAN SACHS DO BRASIL BM S.A" },
    { code: "n/a", name: "CAMARA INTERBANCARIA DE PAGAMENTOS - CIP" },
    { code: "097", name: "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA." },
    { code: "016", name: "CCM DESP TRÂNS SC E RS" },
    { code: "299", name: "BCO SOROCRED S.A. - BM" },
    { code: "012", name: "BANCO INBURSA" },
    { code: "003", name: "BCO DA AMAZONIA S.A." },
    { code: "060", name: "CONFIDENCE CC S.A." },
    { code: "037", name: "BCO DO EST. DO PA S.A." },
    { code: "411", name: "VIA CERTA FINANCIADORA S.A. - CFI" },
    { code: "359", name: "ZEMA CFI S/A" },
    { code: "159", name: "CASA CREDITO S.A. SCM" },
    { code: "085", name: "COOPCENTRAL AILOS" },
    { code: "400", name: "COOP CREDITAG" },
    { code: "410", name: "PLANNER SCM S.A." },
    { code: "114", name: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO" },
    { code: "328", name: "CECM FABRIC CALÇADOS SAPIRANGA" },
    { code: "036", name: "BCO BBI S.A." },
    { code: "394", name: "BCO BRADESCO FINANC. S.A." },
    { code: "004", name: "BCO DO NORDESTE DO BRASIL S.A." },
    { code: "320", name: "BCO CCB BRASIL S.A." },
    { code: "189", name: "HS FINANCEIRA" },
    { code: "105", name: "LECCA CFI S.A." },
    { code: "076", name: "BCO KDB BRASIL S.A." },
    { code: "082", name: "BANCO TOPÁZIO S.A." },
    { code: "312", name: "HSCM SCMEPP LTDA." },
    { code: "195", name: "VALOR SCD S.A." },
    { code: "286", name: "CCR DE OURO" },
    { code: "093", name: "POLOCRED SCMEPP LTDA." },
    { code: "391", name: "CCR DE IBIAM" },
    { code: "273", name: "CCR DE SÃO MIGUEL DO OESTE" },
    { code: "368", name: "BCO CSF S.A." },
    { code: "290", name: "PAGSEGURO S.A." },
    { code: "259", name: "MONEYCORP BCO DE CÂMBIO S.A." },
    { code: "395", name: "F D GOLD DTVM LTDA" },
    { code: "364", name: "GERENCIANET" },
    { code: "157", name: "ICAP DO BRASIL CTVM LTDA." },
    { code: "183", name: "SOCRED SA - SCMEPP" },
    { code: "014", name: "STATE STREET BR S.A. BCO COMERCIAL" },
    { code: "130", name: "CARUANA SCFI" },
    { code: "358", name: "MIDWAY S.A. - SCFI" },
    { code: "127", name: "CODEPE CVC S.A." },
    { code: "079", name: "BCO ORIGINAL DO AGRO S/A" },
    { code: "340", name: "SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A." },
    { code: "081", name: "BANCOSEGURO S.A." },
    { code: "133", name: "CRESOL CONFEDERAÇÃO" },
    { code: "323", name: "MERCADO PAGO" },
    { code: "121", name: "BCO AGIBANK S.A." },
    { code: "083", name: "BCO DA CHINA BRASIL S.A." },
    { code: "138", name: "GET MONEY CC LTDA" },
    { code: "024", name: "BCO BANDEPE S.A." },
    { code: "384", name: "GLOBAL SCM LTDA" },
    { code: "426", name: "BIORC FINANCEIRA - CFI S.A." },
    { code: "088", name: "BANCO RANDON S.A." },
    { code: "319", name: "OM DTVM LTDA" },
    { code: "274", name: "MONEY PLUS SCMEPP LTDA" },
    { code: "095", name: "TRAVELEX BANCO DE CÂMBIO S.A." },
    { code: "094", name: "BANCO FINAXIS" },
    { code: "276", name: "BCO SENFF S.A." },
    { code: "092", name: "BRK S.A. CFI" },
    { code: "047", name: "BCO DO EST. DE SE S.A." },
    { code: "144", name: "BEXS BCO DE CAMBIO S.A." },
    { code: "332", name: "ACESSO SOLUCOES PAGAMENTO SA" },
    { code: "450", name: "FITBANK PAGAMENTOS ELETRONICOS S.A." },
    { code: "126", name: "BR PARTNERS BI" },
    { code: "325", name: "ÓRAMA DTVM S.A." },
    { code: "301", name: "BPP IP S.A." },
    { code: "173", name: "BRL TRUST DTVM SA" },
    { code: "331", name: "FRAM CAPITAL DTVM S.A." },
    { code: "119", name: "BCO WESTERN UNION" },
    { code: "396", name: "HUB PAGAMENTOS" },
    { code: "309", name: "CAMBIONET CC LTDA" },
    { code: "254", name: "PARANA BCO S.A." },
    { code: "268", name: "BARI CIA HIPOTECÁRIA" },
    { code: "401", name: "IUGU IP S.A." },
    { code: "107", name: "BCO BOCOM BBM S.A." },
    { code: "412", name: "BCO CAPITAL S.A." },
    { code: "124", name: "BCO WOORI BANK DO BRASIL S.A." },
    { code: "149", name: "FACTA S.A. CFI" },
    { code: "197", name: "STONE PAGAMENTOS S.A." },
    { code: "313", name: "AMAZÔNIA CC LTDA." },
    { code: "142", name: "BROKER BRASIL CC LTDA." },
    { code: "389", name: "BCO MERCANTIL DO BRASIL S.A." },
    { code: "184", name: "BCO ITAÚ BBA S.A." },
    { code: "634", name: "BCO TRIANGULO S.A." },
    { code: "545", name: "SENSO CCVM S.A." },
    { code: "132", name: "ICBC DO BRASIL BM S.A." },
    { code: "298", name: "VIPS CC LTDA." },
    { code: "377", name: "BMS SCD S.A." },
    { code: "321", name: "CREFAZ SCMEPP LTDA" },
    { code: "260", name: "NU PAGAMENTOS S.A." },
    { code: "129", name: "UBS BRASIL BI S.A." },
    { code: "128", name: "MS BANK S.A. BCO DE CÂMBIO" },
    { code: "416", name: "LAMARA SCD S.A." },
    { code: "194", name: "PARMETAL DTVM LTDA" },
    { code: "383", name: "JUNO" },
    { code: "324", name: "CARTOS SCD S.A." },
    { code: "310", name: "VORTX DTVM LTDA." },
    { code: "380", name: "PICPAY" },
    { code: "163", name: "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO" },
    { code: "280", name: "WILL FINANCEIRA S.A.CFI" },
    { code: "146", name: "GUITTA CC LTDA" },
    { code: "343", name: "FFA SCMEPP LTDA." },
    { code: "279", name: "CCR DE PRIMAVERA DO LESTE" },
    { code: "335", name: "BANCO DIGIO" },
    { code: "349", name: "AL5 S.A. CFI" },
    { code: "427", name: "CRED-UFES" },
    { code: "374", name: "REALIZE CFI S.A." },
    { code: "278", name: "GENIAL INVESTIMENTOS CVM S.A." },
    { code: "271", name: "IB CCTVM S.A." },
    { code: "021", name: "BCO BANESTES S.A." },
    { code: "246", name: "BCO ABC BRASIL S.A." },
    { code: "292", name: "BS2 DTVM S.A." },
    { code: "n/a", name: "Balcão B3" },
    { code: "n/a", name: "CIP C3" },
    { code: "751", name: "SCOTIABANK BRASIL" },
    { code: "352", name: "TORO CTVM LTDA" },
    { code: "208", name: "BANCO BTG PACTUAL S.A." },
    { code: "386", name: "NU FINANCEIRA S.A. CFI" },
    { code: "746", name: "BCO MODAL S.A." },
    { code: "241", name: "BCO CLASSICO S.A." },
    { code: "398", name: "IDEAL CTVM S.A." },
    { code: "336", name: "BCO C6 S.A." },
    { code: "612", name: "BCO GUANABARA S.A." },
    { code: "604", name: "BCO INDUSTRIAL DO BRASIL S.A." },
    { code: "505", name: "BCO CREDIT SUISSE S.A." },
    { code: "329", name: "QI SCD S.A." },
    { code: "196", name: "FAIR CC S.A." },
    { code: "342", name: "CREDITAS SCD" },
    { code: "300", name: "BCO LA NACION ARGENTINA" },
    { code: "477", name: "CITIBANK N.A." },
    { code: "266", name: "BCO CEDULA S.A." },
    { code: "122", name: "BCO BRADESCO BERJ S.A." },
    { code: "376", name: "BCO J.P. MORGAN S.A." },
    { code: "348", name: "BCO XP S.A." },
    { code: "473", name: "BCO CAIXA GERAL BRASIL S.A." },
    { code: "745", name: "BCO CITIBANK S.A." },
    { code: "120", name: "BCO RODOBENS S.A." },
    { code: "265", name: "BCO FATOR S.A." },
    { code: "007", name: "BNDES" },
    { code: "188", name: "ATIVA S.A. INVESTIMENTOS CCTVM" },
    { code: "134", name: "BGC LIQUIDEZ DTVM LTDA" },
    { code: "029", name: "BANCO ITAÚ CONSIGNADO S.A." },
    { code: "243", name: "BANCO MASTER" },
    { code: "397", name: "LISTO SCD S.A." },
    { code: "078", name: "HAITONG BI DO BRASIL S.A." },
    { code: "355", name: "ÓTIMO SCD S.A." },
    { code: "367", name: "VITREO DTVM S.A." },
    { code: "445", name: "PLANTAE CFI" },
    { code: "373", name: "UP.P SEP S.A." },
    { code: "111", name: "OLIVEIRA TRUST DTVM S.A." },
    { code: "408", name: "BONUSPAGO SCD S.A." },
    { code: "402", name: "COBUCCIO SCD S.A." },
    { code: "404", name: "SUMUP SCD S.A." },
    { code: "418", name: "ZIPDIN SCD S.A." },
    { code: "414", name: "WORK SCD S.A." },
    { code: "406", name: "ACCREDITO SCD S.A." },
    { code: "403", name: "CORA SCD S.A." },
    { code: "419", name: "NUMBRS SCD S.A." },
    { code: "435", name: "DELCRED SCD S.A." },
    { code: "421", name: "CC LAR CREDI" },
    { code: "428", name: "CRED-SYSTEM SCD S.A." },
    { code: "448", name: "HEMERA DTVM LTDA." },
    { code: "306", name: "PORTOPAR DTVM LTDA" },
    { code: "017", name: "BNY MELLON BCO S.A." },
    { code: "174", name: "PEFISA S.A. - C.F.I." },
    { code: "433", name: "BR-CAPITAL DTVM S.A." },
    { code: "495", name: "BCO LA PROVINCIA B AIRES BCE" },
    { code: "125", name: "BANCO GENIAL" },
    { code: "488", name: "JPMORGAN CHASE BANK" },
    { code: "065", name: "BCO ANDBANK S.A." },
    { code: "492", name: "ING BANK N.V." },
    { code: "145", name: "LEVYCAM CCV LTDA" },
    { code: "250", name: "BCV - BCO, CRÉDITO E VAREJO S.A." },
    { code: "354", name: "NECTON INVESTIMENTOS S.A CVM" },
    { code: "253", name: "BEXS CC S.A." },
    { code: "269", name: "BCO HSBC S.A." },
    { code: "213", name: "BCO ARBI S.A." },
    { code: "n/a", name: "Câmara B3" },
    { code: "139", name: "INTESA SANPAOLO BRASIL S.A. BM" },
    { code: "018", name: "BCO TRICURY S.A." },
    { code: "422", name: "BCO SAFRA S.A." },
    { code: "630", name: "SMARTBANK" },
    { code: "224", name: "BCO FIBRA S.A." },
    { code: "393", name: "BCO VOLKSWAGEN S.A" },
    { code: "600", name: "BCO LUSO BRASILEIRO S.A." },
    { code: "390", name: "BCO GM S.A." },
    { code: "623", name: "BANCO PAN" },
    { code: "655", name: "BCO VOTORANTIM S.A." },
    { code: "479", name: "BCO ITAUBANK S.A." },
    { code: "456", name: "BCO MUFG BRASIL S.A." },
    { code: "464", name: "BCO SUMITOMO MITSUI BRASIL S.A." },
    { code: "341", name: "ITAÚ UNIBANCO S.A." },
    { code: "237", name: "BCO BRADESCO S.A." },
    { code: "381", name: "BCO MERCEDES-BENZ S.A." },
    { code: "613", name: "OMNI BANCO S.A." },
    { code: "652", name: "ITAÚ UNIBANCO HOLDING S.A." },
    { code: "637", name: "BCO SOFISA S.A." },
    { code: "n/a", name: "Câmbio B3" },
    { code: "653", name: "BANCO VOITER" },
    { code: "069", name: "BCO CREFISA S.A." },
    { code: "370", name: "BCO MIZUHO S.A." },
    { code: "249", name: "BANCO INVESTCRED UNIBANCO S.A." },
    { code: "318", name: "BCO BMG S.A." },
    { code: "626", name: "BCO C6 CONSIG" },
    { code: "270", name: "SAGITUR CC LTDA" },
    { code: "366", name: "BCO SOCIETE GENERALE BRASIL" },
    { code: "113", name: "MAGLIANO" },
    { code: "131", name: "TULLETT PREBON BRASIL CVC LTDA" },
    { code: "011", name: "C.SUISSE HEDGING-GRIFFO CV S/A" },
    { code: "611", name: "BCO PAULISTA S.A." },
    { code: "755", name: "BOFA MERRILL LYNCH BM S.A." },
    { code: "089", name: "CREDISAN CC" },
    { code: "643", name: "BCO PINE S.A." },
    { code: "140", name: "EASYNVEST - TÍTULO CV SA" },
    { code: "707", name: "BCO DAYCOVAL S.A" },
    { code: "288", name: "CAROL DTVM LTDA." },
    { code: "363", name: "SINGULARE CTVM S.A." },
    { code: "101", name: "RENASCENCA DTVM LTDA" },
    { code: "487", name: "DEUTSCHE BANK S.A.BCO ALEMAO" },
    { code: "233", name: "BANCO CIFRA" },
    { code: "177", name: "GUIDE" },
    { code: "438", name: "PLANNER TRUSTEE DTVM LTDA" },
    { code: "365", name: "SIMPAUL" },
    { code: "633", name: "BCO RENDIMENTO S.A." },
    { code: "218", name: "BCO BS2 S.A." },
    { code: "293", name: "LASTRO RDV DTVM LTDA" },
    { code: "285", name: "FRENTE CC LTDA." },
    { code: "080", name: "B&T CC LTDA." },
    { code: "753", name: "NOVO BCO CONTINENTAL S.A. - BM" },
    { code: "222", name: "BCO CRÉDIT AGRICOLE BR S.A." },
    { code: "281", name: "CCR COOPAVEL" },
    { code: "754", name: "BANCO SISTEMA" },
    { code: "311", name: "DOURADA CORRETORA" },
    { code: "098", name: "CREDIALIANÇA CCR" },
    { code: "610", name: "BCO VR S.A." },
    { code: "712", name: "BCO OURINVEST S.A." },
    { code: "720", name: "BCO RNX S.A." },
    { code: "010", name: "CREDICOAMO" },
    { code: "283", name: "RB INVESTIMENTOS DTVM LTDA." },
    { code: "033", name: "BCO SANTANDER (BRASIL) S.A." },
    { code: "217", name: "BANCO JOHN DEERE S.A." },
    { code: "041", name: "BCO DO ESTADO DO RS S.A." },
    { code: "117", name: "ADVANCED CC LTDA" },
    { code: "654", name: "BCO DIGIMAIS S.A." },
    { code: "371", name: "WARREN CVMC LTDA" },
    { code: "212", name: "BANCO ORIGINAL" },
    { code: "289", name: "DECYSEO CC LTDA." }
])